@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Source+Code+Pro:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Source+Code+Pro:wght@200;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Source+Code+Pro:wght@200;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Source+Code+Pro:wght@200;400;500;900&display=swap');


.cardHolder{
  width: 100vw;
  height: 90vh;
  margin: 40px;
  display: flex;
  flex-direction: row;
  align-items: space-between;
}

.imgHolder {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.actionBtn {
    width: 50%;
    background-color: black;
    border: none;
    align-content: left;
}

.actionBtn:hover{
    scale: 1.05;
    background-color: #F3FF16;
    color: black;
}

.textHolder {
    width: 50%;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
}



.mocks {
    height: 625px;
    width: 416.666666px;
}

.title {
    font-size: 40px;
}

.text {
    font-size: 20px
}

.desc{
    font-size: 15px
}

@media screen and (max-width: 775px) {

    .cardHolder{
        width: 100vw;
        height: 50vh;
        margin: 10px;
        display: flex;
        flex-direction: row;
      }

    .mocks {
        width: 200px;
        height: 300px;
    }

    .imgHolder {
        width: 50%;
        background: white;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-size: 15px;
    }

    .text {
        font-size: 10px;
    }

    .desc {
        font-size: 10px;
    }
  
  
  }  
