@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Source+Code+Pro:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Source+Code+Pro:wght@200;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Source+Code+Pro:wght@200;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Source+Code+Pro:wght@200;400;500;900&display=swap');

html, body{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App{
  height: 100%;
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;

}


.hero{
  height: 100vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom:0;
  padding: auto;
  text-align: center;
  margin: auto;
  position: relative;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.title{
  font-family: 'Inter', sans-serif;
  font-family: 'Source Code Pro', monospace;
  font-size: 70px;
  font-weight: 900;
  color: rgb(41, 41, 41);
}

.subTitle{
  font-family: 'Inter', sans-serif;
  font-family: 'Source Code Pro', monospace;
  font-size: 40px;
  align-self: center;
  font-weight: 500;
}

.headshot{
  margin-left: 30px;
}


mark {
  background: #FFFF00!important;
  animation: fadeIn 5s;
}

@media screen and (max-width: 1400px){ 
  .footer{
    opacity: 0;
  }
}

@media screen and (max-width: 775px) {

  .hero{
    width: 80%;
    height: 100vh;
  }
  .title {
    font-size: 50px;
  }

  .subTitle{
    font-size: 20px;
  }

  .downPointer{
    width: 50px;
  }

  .customNav{
    z-index: 1;
  }


}  

@media screen and (max-width: 480px) {
  .title {
    font-size: 35px;
  }

  .subTitle{
    font-size: 14px;
  }

  .downPointer{
    width: 30px;
  }

  .customNav{
    z-index: 1;
  }

  .hero{
    width: 80%;
    height: 100vh;
  }

  .footer{
    display: none;
  }


}

svg{
  width: 100px;
  height: 100px;
  transition:width 2s ease;
  overflow: visible;
  transform: rotate(90deg);
  top: 0;
  left: 0;
}

svg:hover{
  width: 300px;
}

line:hover{
  stroke: black;
}

polygon:hover{
  fill: black;
}

svg:click{
  width: 500px
}

.all{
  height: 90vh;
  border: 1px solid black;
}

.footer{
  height: 10vh;
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  bottom: 0;
}



