.cardsHolder{
    position: relative;
    height: 100vh;
    width: 85vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
}

.card{
    margin: auto;
    height: auto;
    margin-bottom: 30px;
}

.foot{
    height: 30px;
}

@media screen and (max-width: 1400px) {

    .card{
        margin-bottom: 30px;
    }

}

